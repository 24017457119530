import React from "react"

import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

import {
  AiOutlineFacebook,
  AiOutlineLinkedin,
  AiOutlineReddit,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai"
import { FaTelegramPlane } from "react-icons/fa"

import ReactGA from "react-ga"

const Share = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterHandle
            twitterTags
            domain: siteUrl
          }
        }
      }
    `,
  )

  const twitterHandle = site.siteMetadata.twitterHandle
  const tags = site.siteMetadata.twitterTags
  const title = site.siteMetadata.title
  const url = site.siteMetadata.domain

  const shareEvent = (label) => {
    ReactGA.event({
      category: 'Share',
      action: 'Clicked to share on social media',
      label: label
    })
  }

  return (
    <Container className="px-0">
      <Row noGutters className="hero-links">
        <FacebookShareButton url={url} onClick={() => shareEvent("Facebook")}>
          <AiOutlineFacebook className="mr-1 share-icon"/>
        </FacebookShareButton>

        <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags} onClick={() => shareEvent("Twitter")}>
          <AiOutlineTwitter className="mr-1 share-icon"/>
        </TwitterShareButton>

        <LinkedinShareButton url={url} onClick={() => shareEvent("Linkedin")}>
          <AiOutlineLinkedin className="mx-1 share-icon"/>
        </LinkedinShareButton>

        <RedditShareButton url={url} title={title} onClick={() => shareEvent("Reddit")}>
          <AiOutlineReddit className="mx-1 share-icon"/>
        </RedditShareButton>

        <TelegramShareButton url={url} onClick={() => shareEvent("Telegram")}>
          <FaTelegramPlane className="mx-1 share-icon"/>
        </TelegramShareButton>

        <WhatsappShareButton url={url} title={title} onClick={() => shareEvent("Whatsapp")}>
          <AiOutlineWhatsApp className="ml-1 share-icon"/>
        </WhatsappShareButton>
      </Row>
    </Container>
  )
}

export default Share
