import React from "react"

import PropTypes from "prop-types"

import ResourceCard from "./resourceCard"

import { Col, Container, Row } from "react-bootstrap"

const CardList = ({ cards, favorites, addToFavorites, removeFromFavorites }) => {
  return (
    <Container fluid>
      <Row>
        <Col className="cards-wrapper">
          <ResourceCard key={`card_favorites`} data={favorites} handleFavoritesChange={removeFromFavorites}/>
          {
            cards.map((item) =>
              <ResourceCard key={item.id} data={item} handleFavoritesChange={addToFavorites}/>,
            )
          }
        </Col>
      </Row>
    </Container>
  )
}

CardList.propTypes = {
  cards: PropTypes.array,
  favorites: PropTypes.object,
  addToFavorites: PropTypes.func,
  removeFromFavorites: PropTypes.func,
}

CardList.defaultProps = {
  cards: [],
  favorites: {},
}

export default CardList
