import PropTypes from "prop-types"
import React, { useState } from "react"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import ResourceItem from "./resourceItem"

import Image from "react-bootstrap/Image"

import { MdExpandMore, MdExpandLess } from "react-icons/md"
import { BsQuestionCircle } from "react-icons/bs"

import heartImg from "../images/heart.svg"
import heartFilledImg from "../images/heart-filled.svg"
import removeImg from "../images/remove.svg"

const ResourceCard = ({ data, handleFavoritesChange }) => {
  const DEFAULT_ITEMS_TO_SHOW = 5
  const [showMore, setShowMore] = useState(false)
  const [isFavorites] = useState(data && data.title === "Favorites")

  const resolveIcon = item => {
    if (isFavorites) {
      return removeImg
    }

    if (item.isFav) {
      return heartFilledImg
    }

    return heartImg
  }

  const handleClick = () => {
    setShowMore(!showMore)
  }

  if (!data || !data.items) {
    return null
  }

  const allItems = data.items.length
  const itemsToShow = showMore ? allItems : allItems > DEFAULT_ITEMS_TO_SHOW ? DEFAULT_ITEMS_TO_SHOW : allItems
  const diff = allItems - itemsToShow

  return (
    <Card className="resource-card" style={{ width: "21rem" }}>
      <div className="d-flex align-items-center mt-3 ml-3">
        <div className="d-flex align-items-center justify-content-center card-img-container">
          <Image src={data.img} alt="category image"/>
        </div>
        <Card.Title className="my-0 ml-2 mr-1">
          {data.title}
          {
            isFavorites ?
              <BsQuestionCircle className="card-hint ml-1" title="You can add your favorite items here"/>
              : ""
          }
        </Card.Title>
      </div>
      <Card.Body className="pt-2 pb-0">
        <ListGroup variant="flush">
          {
            data.items.slice(0, itemsToShow).map((item, index) =>
              <ResourceItem key={`item_${index}_${item.id}`} item={item} favIcon={resolveIcon(item)}
                            handleFavoritesChange={handleFavoritesChange}/>,
            )
          }
        </ListGroup>
      </Card.Body>
      <Card.Footer className="text-center">
        <div className="d-flex justify-content-center toggle-expand"
             role="button" tabIndex={0}
             aria-label="expand"
             onClick={() => handleClick()}
             onKeyDown={() => handleClick()}>
          <MoreOrLess showMore={showMore} diff={diff}/>
        </div>
      </Card.Footer>
    </Card>
  )
}

const MoreOrLess = ({ showMore, diff }) => {
  if (!showMore && diff === 0) {
    return null
  }
  if (showMore) {
    return (
      <div className="d-flex align-items-center">
        <span>Less</span>
        <MdExpandLess className="ml-1"/>
      </div>
    )
  }
  return (
    <div className="d-flex align-items-center">
      <span>See more</span>
      <MdExpandMore className="ml-1"/>
    </div>
  )
}

ResourceCard.propTypes = {
  data: PropTypes.object,
}

ResourceCard.defaultProps = {
  data: {},
}

export default ResourceCard
