import React, { useEffect, useState, useCallback } from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import JSONData from "../content/data"
import JSONFavoritesTemplate from "../content/favorites_template"

import CardList from "../components/cardList"

import _lodash from "lodash"

import SearchBar from "../components/searchBar"
import Hero from "../components/hero"

import ReactGA from "react-ga"

const IndexPage = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [favorites, setFavorites] = useState(typeof window !== "undefined"
    && (JSON.parse(window.localStorage.getItem("favorites")) || JSONFavoritesTemplate))

  const data = JSONData.content

  const handleSearchChange = e => {
    e.preventDefault()
    const text = e.target.value
    setSearchQuery(text)
  }

  const addToFavorites = (item) => {
    if (existsInFavorites(item)) {
      return
    }

    item.isFav = true

    setFavorites({
      ...favorites,
      items: [
        ...favorites.items,
        item,
      ],
    })

    ReactGA.event({
      category: 'Favorites',
      action: 'Added Favorite',
      label: item.url
    })
  }

  const removeFromFavorites = (item) => {
    let items = [...favorites.items]
    const index = items.indexOf(item)
    if (index !== -1) {
      item.isFav = false

      items.splice(index, 1)

      setFavorites({
        ...favorites,
        items,
      })

      ReactGA.event({
        category: 'Favorites',
        action: 'Removed Favorite',
        label: item.url
      })
    }
  }

  const existsInFavorites = useCallback((item) => {
    if (!favorites || !favorites.items) {
      return false
    }
    return favorites.items.find(element => element.id === item.id)
  }, [favorites])

  useEffect(() => {
    data.forEach(resource => {
      resource.items.map(item => ({ ...item, isFav: existsInFavorites(item) }))
    })
  }, [favorites, data, existsInFavorites])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("favorites", JSON.stringify(favorites))
    }
  }, [favorites])

  useEffect(() => {
    const searchQueryLC = searchQuery.toLowerCase()
    let filtered = _lodash.cloneDeep(data)
    if (searchQueryLC && searchQueryLC.length > 0) {
      filtered = filtered.filter(resource => {
        if (resource.title.toLowerCase().includes(searchQueryLC)) {
          return true
        } else {
          resource.items = resource.items.filter(item => item.title.toLowerCase().includes(searchQueryLC))
          return resource.items.length > 0
        }
      })
    }
    setFilteredData(filtered)
  }, [searchQuery, data])

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Know Ethereum"
           keywords={[`Ethereum`, `Ethereum resources`, `Blockchain`, `Ethereum learning`, `Ethereum programming`, `Ethereum communities`]}/>
      <Hero/>
      <Container fluid className="text-center justify-content-center pt-5 resources-container">
        <Row className="mb-4">
          <Col>
            <p className="resources-title">Resources</p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <SearchBar searchQuery={searchQuery} handleSearchChange={handleSearchChange}/>
          </Col>
        </Row>
        <Row className="pb-5">
          <Col>
            <CardList cards={filteredData}
                      favorites={favorites}
                      addToFavorites={addToFavorites}
                      removeFromFavorites={removeFromFavorites}/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
