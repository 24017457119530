import PropTypes from "prop-types"
import React from "react"
import ListGroup from "react-bootstrap/ListGroup"
import Image from "react-bootstrap/Image"

import ReactGA from "react-ga"

const ResourceItem = ({ item, favIcon, handleFavoritesChange }) => (
  <ListGroup.Item className="d-flex align-items-center text-left mt-1 mb-1 resource-item">
    <Image className="mr-2"
           height="16" width="16"
           src={`https://www.google.com/s2/favicons?domain=${item.url}`}
           alt="icon"/>
    <ReactGA.OutboundLink to={item.url + "?utm_source=KnowEthereum&utm_content=visit-website"} target="_blank" 
                          rel='noopener noreferrer' className="mr-2 card-link" eventCategory="Resource" eventLabel={item.url}>
      {item.title}
    </ReactGA.OutboundLink>
    <Image className="ml-auto favorites-icon"
           height="16" width="18"
           src={favIcon}
           alt="favorite"
           onClick={() => handleFavoritesChange(item)}/>
  </ListGroup.Item>
)

ResourceItem.propTypes = {
  item: PropTypes.object,
}

ResourceItem.defaultProps = {
  item: {},
}

export default ResourceItem
