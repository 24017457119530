import { Button, Col, Container, Row } from "react-bootstrap"
import React from "react"
import { Link } from "gatsby"

import ethImg from "../images/eth.svg"
import Image from "react-bootstrap/Image"

import Particles from "react-particles-js"

import particlesJSON from "../particles/blockchain-particles"

import { FiCoffee } from "react-icons/fi"

import Share from "./share"

import ReactGA from "react-ga"

const Hero = () => (
  <Container fluid className="px-0 mx-0 py-5 hero-container">
    <Row noGutters>
      <Col className="col-12 col-md-4 offset-md-1 pl-5">
        <Container>
          <Row>
            <h1 className="hero-title">
              Handpicked Ethereum Resources.
              <hr className="hero-separator"/>
            </h1>
          </Row>
          <Row>
            <p className="mt-4 hero-text">
              Discover high-quality curated resources for the Ethereum ecosystem.
              Learn, explore and utilize the possibilities of this revolutionising technology.
              Expand your knowledge and experience in Ethereum!
            </p>
          </Row>
          <Row>
            <Link to='/about'>
              <Button className="mt-4 mb-3 px-5 suggest-btn">Read more</Button>
            </Link>
          </Row>
          <Row className="mt-5 hero-links">
            <ReactGA.OutboundLink eventLabel="Donate" to="https://etherscan.io/address/0x09cf79bdf8f68739979c8c825c103a7538bd4f4b" 
                                  target="_blank" rel='noopener noreferrer' aria-label="etherscan address">
              Like the content? You can buy me a {` `} <FiCoffee/>
            </ReactGA.OutboundLink>
          </Row>
          <Row className="mt-3">
            <p className="hero-text">Or share it with your fellas:</p>
            <Share/>
          </Row>
        </Container>
      </Col>
      <Col className="col-12 col-md-4 offset-md-2 pr-5">
        <div className="d-flex justify-content-center align-items-center particles-wrapper">
          <Particles
            className="w-100 h-100"
            params={particlesJSON}/>
          <Image src={ethImg} alt="ETH image" className="mx-auto eth-image"/>
        </div>
      </Col>
    </Row>
  </Container>
)

Hero.propTypes = {}

Hero.defaultProps = {}

export default Hero
