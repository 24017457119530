import PropTypes from "prop-types"
import React from "react"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import { BsSearch } from "react-icons/bs"

const SearchBar = ({ searchQuery, handleSearchChange }) => (
  <Form inline className="cards-wrapper">
    <Form.Group style={{ width: "21rem" }}>
      <InputGroup className="d-flex align-items-center w-100 search-group">
        <FormControl
          id="search-text"
          className="shadow-none"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search..."
          aria-label="Search"
        />
        <BsSearch className="position-absolute mr-2 search-icon"/>
      </InputGroup>
    </Form.Group>
  </Form>
)

SearchBar.propTypes = {
  searchQuery: PropTypes.string,
  handleSearchChange: PropTypes.func,
}

SearchBar.defaultProps = {
  searchQuery: ``,
}

export default SearchBar
